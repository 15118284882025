import { IProductData, TPathParams, getCompleteSelectionData } from '@/utils';
import { TCartItem } from '@/lib/cart/useCart';
import { deslugify } from '@/lib/utils';
import { useCartContext } from '@/providers/CartProvider';
import { useParams } from 'next/navigation';
import { useEffect } from 'react';
import { useStore } from 'zustand';
import { US_STATES_ABBRV_MAP } from '@/lib/constants';
import { IS_PREVIEW } from '@/utils/env';
import useStoreContext from './useStoreContext';

export const removeMakeFromDisplayId = (
  displayId: string,
  make: string
): string => {
  if (displayId === null || make === null) {
    return displayId ?? ''; // Return displayId if it's not null, otherwise return an empty string
  }

  if (displayId?.includes(make)) {
    return displayId?.replace(make, '').trim();
  }
  return displayId;
};

const generateViewItemEvent = (
  selectedProduct: IProductData,
  params: TPathParams | null,
  isComplete: boolean
) => {
  // const price = parseFloat(selectedProduct?.price || '0') || 0;
  const msrp = parseFloat(selectedProduct?.msrp || '0') || 0;
  // const discount: number = price - msrp;
  const {
    year = '',
    make = '',
    model = '',
    coverType = '',
    productType = '',
  } = params || {};

  const productName = isComplete
    ? `${selectedProduct.fullProductName} ${deslugify(coverType)} ${selectedProduct.type}`
    : `${year} ${deslugify(make)} ${deslugify(model)} ${deslugify(coverType)} ${deslugify(productType)}`
        .replace(/  +/g, ' ')
        .trim();

  const item = {
    item_id: isComplete ? selectedProduct?.sku : undefined,
    item_name: productName,
    affiliation: undefined,
    coupon: undefined,
    discount: undefined,
    index: 0,
    item_brand: 'Coverland',
    item_category: deslugify(
      params?.productType || selectedProduct?.type || ''
    ),
    item_category2: deslugify(
      params?.coverType || selectedProduct?.display_id || ''
    ),
    item_category3: deslugify(params?.make || selectedProduct?.make || ''),
    item_category4: deslugify(params?.model || selectedProduct?.model || ''),
    item_category5: params?.year || selectedProduct?.parent_generation || '',
    item_category6: isComplete
      ? deslugify(selectedProduct?.submodel1 || '')
      : undefined,
    item_category7: isComplete
      ? deslugify(selectedProduct?.submodel2 || '')
      : undefined,
    item_category8: isComplete
      ? deslugify(selectedProduct?.submodel3 || '')
      : undefined,
    item_list_id: undefined,
    item_list_name: undefined,
    item_variant: selectedProduct?.display_color,
    location_id: undefined,
    price: isComplete ? msrp : undefined,
    quantity: 1,
    in_stock: !selectedProduct?.preorder, // If Preorder is TRUE, then that means out of stock
  };

  return item;
};

const mapCartItemsToGTagItems = (cartItems: TCartItem[]) => {
  return cartItems.map((cartItem, index) => {
    const cleanedDisplayId = removeMakeFromDisplayId(
      cartItem.display_id as string,
      cartItem.make as string
    );
    const fullProductName =
      `${cartItem.year_generation ?? ''} ${cartItem.make ?? ''} ${cartItem.model ?? ''} ${cartItem.submodel1 ?? ''} ${cartItem.submodel2 ?? ''} ${cartItem.submodel3 ?? ''}`.trim();
    const productName = `${fullProductName} ${cleanedDisplayId} ${cartItem.type}`;
    // const price = parseFloat(cartItem?.price || '0') || 0;
    const msrp = parseFloat(cartItem?.msrp || '0') || 0;
    // const discount: number = price - msrp;

    return {
      item_id: cartItem?.sku,
      item_name: productName,
      affiliation: undefined,
      coupon: undefined,
      discount: undefined, // Removed temporarily because we transfer the promotional price or something
      index: index,
      item_brand: 'Coverland',
      item_category: cartItem.type,
      item_category2: cleanedDisplayId,
      item_category3: cartItem.make,
      item_category4: cartItem.model,
      item_category5: cartItem.parent_generation,
      item_category6: cartItem.submodel1,
      item_category7: cartItem.submodel2,
      item_category8: cartItem.submodel3,
      item_list_id: undefined,
      item_list_name: undefined,
      item_variant: cartItem.display_color,
      location_id: undefined,
      price: msrp,
      quantity: cartItem.quantity,
      in_stock: !cartItem?.preorder, // If Preorder is TRUE, then that means out of stock
    };
  });
};

export const useItemViewedGoogleTag = (selectedProduct: IProductData) => {
  if (IS_PREVIEW) return;
  const store = useStoreContext();
  if (!store) throw new Error('Missing Provider in the tree');
  const params = useParams<TPathParams>();
  const modelData = useStore(store, (s) => s.modelData);

  const {
    completeSelectionState: { isComplete },
  } = getCompleteSelectionData({
    data: modelData,
  });

  useEffect(() => {
    const msrp = parseFloat(selectedProduct?.msrp || '0') || 0;
    const item = generateViewItemEvent(selectedProduct, params, isComplete);
    window?.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window?.dataLayer?.push({
      event: 'view_item',
      ecommerce: {
        currency: 'USD',
        value: isComplete ? msrp : undefined,
        items: [item],
      },
    });
  }, [params, selectedProduct, isComplete]);
};

export const useCheckoutViewedGoogleTag = () => {
  if (IS_PREVIEW) return;
  const { cartItems, getCartTotalPrice } = useCartContext();
  useEffect(() => {
    const cartItemsToGTagItems = mapCartItemsToGTagItems(cartItems);
    window?.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window?.dataLayer?.push({
      event: 'begin_checkout',
      ecommerce: {
        currency: 'USD',
        value: parseFloat(getCartTotalPrice().toFixed(2)),
        coupon: undefined,
        items: cartItemsToGTagItems,
      },
    });
  }, [cartItems, getCartTotalPrice]);
};

export const useThankYouViewedGoogleTag = (orderNumber: string) => {
  if (IS_PREVIEW) return;
  const { cartItems, getCartTotalPrice, clearLocalStorageCart } =
    useCartContext();
  useEffect(() => {
    if (typeof window !== 'undefined' && window.performance) {
      const navigationType = window.performance.navigation.type;
      if (navigationType === PerformanceNavigation.TYPE_RELOAD) {
        console.log('Page was reloaded, GTAG not tracked.');
      } else {
        const cartItemsToGTagItems = mapCartItemsToGTagItems(cartItems);
        window?.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window?.dataLayer?.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: orderNumber,
            // Sum of (price * quantity) for all items.
            value: parseFloat(getCartTotalPrice().toFixed(2)),
            tax: 0.0, // Femi working on this
            shipping: 0.0, // Free shipping for now
            currency: 'USD',
            coupon: undefined, // will need to put in coupon for later but we don't track this ATM
            items: cartItemsToGTagItems,
          },
        });
        if (cartItems.length > 0) {
          console.log('[useThankYouViewedGoogleTag] clearLocalCart');

          clearLocalStorageCart();
        }
      }
    }
  }, [cartItems, getCartTotalPrice, orderNumber, clearLocalStorageCart]);
};

export const handlePurchaseGoogleTag = (
  cartItems: TCartItem[],
  orderNumber: string,
  totalPrice: string,
  clearLocalStorageCart: () => void,
  enhancedParameterInput: EnhancedGoogleConversionInput,
  tax: number
) => {
  if (IS_PREVIEW) return;
  const cartItemsToGTagItems = mapCartItemsToGTagItems(cartItems);
  window?.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window?.dataLayer?.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: orderNumber,
      // Sum of (price * quantity) for all items.
      value: parseFloat(totalPrice),
      tax,
      shipping: 0.0, // Free shipping for now
      currency: 'USD',
      coupon: undefined, // will need to put in coupon for later but we don't track this ATM
      items: cartItemsToGTagItems,
    },
    enhanced_conversion_data: createEnhancedGoogleConversionData({
      ...enhancedParameterInput,
    }),
  });

  if (cartItems.length > 0) {
    clearLocalStorageCart();
  }
};

export type EnhancedGoogleConversionInput = {
  email: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  address_line1: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
};

export const createEnhancedGoogleConversionData = ({
  email,
  phone_number,
  first_name,
  last_name,
  address_line1,
  city,
  state,
  postal_code,
  country,
}: EnhancedGoogleConversionInput) => {
  return {
    email: email.toLowerCase().trim(),
    phone_number: phone_number,
    first_name: first_name.toLowerCase().trim(),
    last_name: last_name.toLowerCase().trim(),
    street: address_line1.toLowerCase().trim(),
    city: city.toLowerCase().trim(),
    region: US_STATES_ABBRV_MAP[state].toLowerCase().trim(),
    postal_code: postal_code.toLowerCase().trim(),
    country: country.toLowerCase().trim(),
  };
};

export const handleAddToCartGoogleTag = (
  cartProducts: IProductData | IProductData[],
  params: TPathParams
) => {
  if (IS_PREVIEW) return;
  const products = Array.isArray(cartProducts) ? cartProducts : [cartProducts]; // Ensure we have an array
  const items = products.map((product) => {
    // const price = parseFloat(product?.price || '0') || 0;
    const msrp = parseFloat(product?.msrp || '0') || 0;
    // const discount: number = price - msrp;
    const cleanedDisplayId = removeMakeFromDisplayId(
      product.display_id as string,
      product.make as string
    );
    const fullProductName =
      `${product.year_generation ?? ''} ${product.make ?? ''} ${product.model ?? ''} ${product.submodel1 ?? ''} ${product.submodel2 ?? ''} ${product.submodel3 ?? ''}`.trim();
    const productName = `${fullProductName} ${cleanedDisplayId} ${product?.type}`;
    return {
      item_id: product.sku,
      item_name: productName,
      currency: 'USD',
      value: msrp,
      affiliation: undefined,
      coupon: undefined,
      discount: undefined, // Removed temporarily because we transfer the promotional price or something
      index: 0,
      item_brand: 'Coverland',
      item_category: deslugify(
        params?.productType || product?.product_type || product?.type || ''
      ),
      item_category2: deslugify(params?.coverType || product.display_id || ''),
      item_category3: deslugify(params?.make || product.make || ''),
      item_category4: deslugify(params?.model || product.model || ''),
      item_category5: params?.year || product.parent_generation || '',
      item_category6: deslugify(product?.submodel1 || ''),
      item_category7: deslugify(product?.submodel2 || ''),
      item_category8: deslugify(product?.submodel3 || ''),
      item_list_id: undefined,
      item_list_name: undefined,
      item_variant: product?.display_color,
      location_id: undefined,
      price: msrp,
      quantity: 1,
      in_stock: !product?.preorder, // If Preorder is TRUE, then that means out of stock
    };
  });

  window?.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window?.dataLayer?.push({
    event: 'add_to_cart',
    ecommerce: {
      currency: 'USD',
      value: items.reduce((acc, item) => acc + item.value, 0), // Sum of all msrp values
      items,
    },
  });
};

export const handleViewItemColorChangeGoogleTag = (
  selectedProduct: IProductData,
  params: TPathParams | null,
  isComplete: boolean
) => {
  if (IS_PREVIEW) return;
  const msrp = parseFloat(selectedProduct?.msrp || '0') || 0;
  const item = generateViewItemEvent(selectedProduct, params, isComplete);
  window?.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window?.dataLayer?.push({
    event: 'view_item',
    ecommerce: {
      currency: 'USD',
      value: isComplete ? msrp : undefined,
      items: [item],
    },
  });
};

export const handlePreorderAddToCartGoogleTag = (
  cartProducts: IProductData | IProductData[], // Accepts single or array of products
  params: TPathParams,
  eventTitle:
    | 'preorder_1_add-to-cart'
    | 'preorder_2_discount-and-date'
    | 'preorder_3_acknowledge-checkbox'
    | 'acknowledge-checkbox_floor-mat-pattern'
) => {
  if (IS_PREVIEW) return;
  const products = Array.isArray(cartProducts) ? cartProducts : [cartProducts]; // Ensure we have an array
  const items = products.map((product) => {
    // const price = parseFloat(product?.price || '0') || 0;
    const msrp = parseFloat(product?.msrp || '0') || 0;
    // const discount: number = price - msrp;
    const cleanedDisplayId = removeMakeFromDisplayId(
      product.display_id as string,
      product.make as string
    );
    const fullProductName =
      `${product.year_generation ?? ''} ${product.make ?? ''} ${product.model ?? ''} ${product.submodel1 ?? ''} ${product.submodel2 ?? ''} ${product.submodel3 ?? ''}`.trim();
    const productName = `${fullProductName} ${cleanedDisplayId} ${product?.type}`;

    return {
      item_id: product.sku,
      item_name: productName,
      currency: 'USD',
      value: msrp,
      affiliation: undefined,
      coupon: undefined,
      discount: undefined, // Removed temporarily because we transfer the promotional price or something
      index: 0,
      item_brand: 'Coverland',
      item_category: deslugify(
        params?.productType || product?.product_type || product?.type || ''
      ),
      item_category2: deslugify(params?.coverType || product.display_id || ''),
      item_category3: deslugify(params?.make || product.make || ''),
      item_category4: deslugify(params?.model || product.model || ''),
      item_category5: params?.year || product.parent_generation || '',
      item_category6: deslugify(product?.submodel1 || ''),
      item_category7: deslugify(product?.submodel2 || ''),
      item_category8: deslugify(product?.submodel3 || ''),
      item_list_id: undefined,
      item_list_name: undefined,
      item_variant: product?.display_color,
      location_id: undefined,
      price: msrp,
      quantity: 1,
      in_stock: !product?.preorder, // If Preorder is TRUE, then that means out of stock
    };
  });

  window?.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window?.dataLayer?.push({
    event: eventTitle,
    ecommerce: {
      currency: 'USD',
      value: items.reduce((acc, item) => acc + item.value, 0), // Sum of all msrp values
      items,
    },
  });
};
