import { DateTime } from 'luxon';

// Example: 1713216693  -> April 15, 2024, at 21:31:33
export const convertUnixTimestampToUTCDate = (timestamp: number) => {
  // Convert Unix timestamp to milliseconds by multiplying by 1000
  const date = new Date(timestamp * 1000);
  return date.toString();
};

// Example: "Mon Apr 15 2024 17:43:25 GMT-0700 (Pacific Daylight Time)" -> "2024-04-16T00:43:25.000Z"
export const convertDateStringToPostgresqlTimestamp = (dateString: string) => {
  const date = new Date(dateString);
  return date.toISOString();
};

// Example: 1713216693 -> "2024-04-16T00:43:25.000Z"
export const convertUnixTimestampToISOString = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  return date.toISOString();
};

export const getCurrentTimeInISOFormat = () => {
  return new Date().toISOString();
};

// Outputs: "May 10, 2024" (if today is May 10, 2024)
export const getCurrentDayInLocaleDateString = () => {
  const date = new Date();
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return formattedDate;
};

// Output Example: 2024-06-07T13:01:00-07:00
export const getCurrentDateInPST = () => {
  const pstDate = DateTime.now().setZone('America/Los_Angeles').toISO();
  return pstDate;
};

/**
 *
 * @param isoDateString "2024-06-07T13:01:00-07:00"
 * @returns string "06/07/2024"
 */
export function formatISODate(isoDateString: string): string {
  const date = new Date(isoDateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    throw new Error('Invalid ISO date string');
  }

  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

/**
 * Need this one because without a timezone, it'll assume UTC midnight. This will cause the date to shift.
 * @param isoDateString "2024-06-07"
 * @returns string "06/07/2024"
 */
export function formatISODateNoTimeZone(isoDateString: string): string {
  const [year, month, day] = isoDateString.split('-');

  if (!year || !month || !day) {
    throw new Error('Invalid ISO date string');
  }

  return `${month}/${day}/${year}`;
}

/**
 * Calculates value of weeks from current date.
 * Note: Only returns the number. Don't forget to add "weeks" after
 * @param targetDate
 * @returns
 */
export function weeksFromCurrentDate(targetDate: string): number {
  const currentDate = new Date();
  const target = new Date(targetDate);

  const diffInMilliseconds = target.getTime() - currentDate.getTime();
  const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
  const diffInWeeks = diffInDays / 7;

  return Math.ceil(diffInWeeks);
}

export function getCurrentMonth() {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const date = new Date();
  return monthNames[date.getMonth()];
}

export function calculateTimeTo2PM() {
  const now = new Date();
  const target = new Date();

  target.setHours(14); // Set to 2 PM local time
  target.setMinutes(0);
  target.setSeconds(0);
  target.setMilliseconds(0);

  if (now.getHours() >= 14) {
    // Adjust for PST timezone offset
    target.setDate(target.getDate() + 1); // Set to next day if past 2 PM
  }

  const diff: number = target.getTime() - now.getTime();

  // Convert milliseconds to hours and minutes
  const hours = Math.floor(diff / 1000 / 60 / 60);
  const minutes = Math.floor((diff / 1000 / 60) % 60);

  if (hours < 0 || minutes < 0) {
    return ''; // In case of negative values
  }

  return `${hours} hours ${minutes} mins`;
}

// NTS: Turn this into a more re-usable function later
// Was causing multiple re-renders when I was trying to turn it into one, probably a different issue though.
const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export function calculateTimeTo11AM(
  warehouseTimezone: string = DEFAULT_TIMEZONE
) {
  // Get current warehouse time
  const warehouseNow = DateTime.now().setZone(warehouseTimezone);

  // Create target time (11 AM) in warehouse timezone
  let target = warehouseNow.set({
    hour: 11,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  // If current time is past 11 AM, set target to next day
  if (warehouseNow.hour >= 11) {
    target = target.plus({ days: 1 });
  }

  // Calculate difference
  const diff = target.diff(warehouseNow, ['hours', 'minutes']).toObject();

  const hours = Math.floor(diff.hours || 0);
  const minutes = Math.floor(diff.minutes || 0);

  if (hours < 0 || minutes < 0) {
    return '';
  }

  return hours === 0 ? `${minutes} mins` : `${hours} hours ${minutes} mins`;
}

export function cardIsExpired(
  expiryMonth: string,
  expiryYear: string
): boolean {
  // Get the current date
  const currentDate = new Date();

  // Create a Date object for the card expiry (set to the last day of the expiry month)
  const expiryDate = new Date(
    parseInt(expiryYear),
    parseInt(expiryMonth) - 1 + 1,
    0
  );

  // Check if the expiry date is before the current date
  return expiryDate < currentDate;
}
